<template>
  <div>
      <v-row>
        <v-col cols="6" md="12">
          <div class=" ml-4 d-flex justify-space-between">
            <span class="title mt-1">
              Review Phase
            </span>

            <v-btn-toggle
              title
              dense
              rounded
              v-model="reviewPhase"
            >
              <v-btn active-class="success"  @click="fetchMyComments(1)">
                Review-1
              </v-btn>
              <v-btn active-class="success"  @click="fetchMyComments(2)">
                Review-2
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>

      <div v-if="score && score.team_score ">
        <v-card
            class="mx-auto mt-3"
            outlined
        >

            <v-card-text class="title mt-1">
               Scores
            </v-card-text>

            <v-card-text class='d-flex justify-space-between subtitle-1'>
                <span>
                    Team Score for final project
                </span>
                <span class="title primary--text">
                    {{score.team_score}}
                </span>
            </v-card-text>

            <v-card-text class='d-flex justify-space-between subtitle-1'>
                <span>
                    My Grade
                </span>
                <span class="title primary--text">
                    {{score.student_score}}
                </span>
            </v-card-text>

            <v-divider></v-divider>
            
            <v-card-text>
              <span class="title mt-1">
               Tip.
              </span>
              <v-alert
                border="right"
                colored-border
                type="info"
                elevation="0"
              >
                {{generateMotivationalText(score.sapa_score)}}
              </v-alert>
            </v-card-text>

            <v-card-text v-if='score.applied_penalty || score.team_penalty'>
              <span class="title mt-1">
               Penalties Applied. 
              </span>
              <ul class="subtitle-1 mt-2">
                <li v-if='score.applied_penalty'>A Penalty of 10% has been applied to your score, since did not rate all your peers.</li>
              </ul>
              <ul class="subtitle-1">
                <li v-if='score.team_penalty'>An added Penalty of {{score.team_penalty}}% has been applied to your score by the course administrator.</li>
              </ul>
            </v-card-text>
        </v-card>
      </div>

      <v-card
          class="mx-auto  mt-3"
          outlined
      >            
        <v-card-text class="title mt-1">
            How have your peer(s) rated your participation/contribution (Average rating)?
        </v-card-text>

        <v-card-text v-for="(c, idx) in peerScores" :key="'rating-' + idx" class="d-flex justify-space-between">
            <span class="subtitle-1">
                {{c.criteriaDescription}}
            </span>
            <span class="title primary--text bold">
                {{c.averageScore}} / {{c.weightage}}
            </span>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-title class="title mt-1">
            What does your peer have to say about your participation/contribution?
        </v-card-title>

        <v-card-text v-for="(c, idx) in comments" :key="'peer-' + idx">
            <ul class="subtitle-1">
                <li>{{c}}</li>
            </ul>
        </v-card-text>
      </v-card>

  </div>
</template>

<script>
import { fetchProjectPeerComments, fetchStudentScoreProject, fetchProjectPeerRating } from '../../api/course'

export default {
  components: {
  },
  data() {
    return {
      reviewPhase: 0,
      score: {},
      defaultSelected: 1,
      comments: [],
      peerScores: []
    }
  },
  mounted(){
    this.fetchMyComments(1)
  },
  computed: {
  },
  methods:{
    generateMotivationalText(sapa){
      if(!sapa){
        return 'Please participate in the Self / Peer assessment process. Thanks.'
      }

      if(sapa < 0.95){
        return 'You are doing great. Next time give yourself a better score dring the self assessment!'
      }

      if(sapa > 1.05){
        return 'Your peers have rated you lower. Do introspect and reach out to the course coordinator for tips.'
      }

      return 'Great Job! Your self assessment scores are in line with how you have been perceived by your peers.'
    },
    async fetchMyComments(reviewPhase){
      this.comments = []
      try{
        const comments = await fetchProjectPeerComments({
          reviewPhase: reviewPhase
        })

        if(comments && comments.data && comments.data.length > 0){

          let op = comments.data.map(function(item) {
            return item.comment;
          });

          this.comments = op;
        }
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
      finally{
        if(this.comments.length == 0){
            this.comments.push('No peer feedback comments available :(')
        }

        this.fetchMyScore(reviewPhase)
      }
    },
    async fetchPeerRating(reviewPhase){
      if( reviewPhase ){
        try{
            const apiData = await fetchProjectPeerRating({reviewPhase: reviewPhase})        
            this.peerScores = apiData.data           
        }
        catch(e){
          this.peerScores = []
          if(e.code === 'ERR_BAD_REQUEST'){
            this.$toast.open({
              message: e.response.data.details || e.response.data.error.message,
              type: 'error',
            });
          }
          else{
            this.$toast.open({
              message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
              type: 'error',
            });
          }
        }
      }
      else{
        this.peerScores = []
      }
    },
    async fetchMyScore(reviewPhase){
      this.score = {}
      try{
        const score = await fetchStudentScoreProject({reviewPhase: reviewPhase})

        if(score && score.data && score.data.length > 0){
          let op = score.data[0]
          this.score = op
        }

        this.fetchPeerRating(reviewPhase)
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
    },
  },
}
</script>

